$('.slider').slick({
    slidesToShow: 4,
    infity: true,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '<span class="slick-prev"></span>',
    nextArrow: '<span class="slick-next"></span>',

    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 553,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});
